<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title ">
                                    <h4 class="mb-4">Cambiar contraseña</h4>
                                    <p>Ingresa tu nueva contraseña</p>
                                </div>
                                <input type="password" label-placeholder="Contraseña" v-model="passwordNueva" class="w-full mb-8" />
                                <div class="vx-card__title ">
                                    <p>Confirmar contraseña</p>
                                </div>
                                <div class="">
                                <input type="password" label-placeholder="Contraseña" v-model="password" class="w-full" name="password"/>
                                <span class="text-danger span-placeholder" v-show="validacion">Las contraseñas no coinciden </span>
                                </div>
                               <!-- <vs-button to="/login" class="px-4 w-full md:w-auto">Regresar</vs-button>-->
                               
                            </div>
                             <vs-button class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0" @click="cambiarContraseña()" :disabled="!formValido">Cambiar contraseña</vs-button>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import md5 from 'js-md5';
export default {
  data () {
    return {
      password:'',
      passwordNueva:'',
      email:''
    }
  },
   computed:{
    validacion() {
      return this.password !== this.passwordNueva
    },
    formValido(){
        return this.passwordNueva != '' && this.passwordNueva != '' && !this.validacion
    }
   }, 
  mounted(){
      this.email=this.$route.params.id
  },
   methods:{
     cambiarContraseña(){
      let self=this
      var objRequestRegistraEjecutivo
        objRequestRegistraEjecutivo = {
          strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
          strMetodo: "CambiaPassword",
          objEjecutivo: {
            EMail: this.email,
            Password: md5(this.password),
          }
        }
      this.$axios.post('/',objRequestRegistraEjecutivo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
              this.$router.push('/login');
            //this.$store.commit('UPDATE_USER_INFO', response.data.objContenido)
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en Cambiar contraseña',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    }
   }
}
</script>
